angular.module("vgresiduos").controller("DisposalIntegrationsInfoPopupCtrl", [
	"$scope",
	"httpService",
	"dialogService",
	"disposalService",
	"asyncTimeoutService",
	"residuesManagementService",
	"localVariables",
	"$rootScope",
	function (
		$scope,
		httpService,
		dialogService,
		disposalService,
		asyncTimeoutService,
		residuesManagementService,
		localVariables,
		$rootScope
	) {
		"use strict";

		const statusEnum = Vgr.enumerations.destination.systemStatus;
		$scope.systemsToPopup = localVariables.systemsToPopup;
		$scope.editPermission = localVariables.editPermission;
		$scope.getSystemStatusDescription = localVariables.getSystemStatusDescription;
		$scope.destinationModel = localVariables.destinationModel;
		$scope.readOnlyModel = localVariables.readOnlyModel;
		$scope.labels = $rootScope.labels;
		$scope.onUpdateDisposalManifest = localVariables.onUpdateDisposalManifest;
		$scope.listenForDisposalFilesEvents = localVariables.listenForDisposalFilesEvents;

		$scope.toggleShowDetails = function (integrationInfo) {
			integrationInfo.isToShowSummary = !integrationInfo.isToShowSummary;
		};
		function setIntegrationsInfo() {
			$scope.integrationsInfo = [];
			for (const system of $scope.systemsToPopup) {
				$scope.integrationsInfo.push({
					SourceId: system.SourceId,
					Description: system.Description,
					Manifests: system.Manifests.map((dm) => {
						return { ...dm, Residues: [], ResiduesSummary: [] };
					}),
					StatusDescriptionSummary: "",
					StatusDescriptionClassSummary: "",
					ReceiveDateSummary: "",
					isToShowSummary: true,
					ResiduesSummary: []
				});
			}
		}

		function setDisposalStatusInfo(integrationInfo) {
			for (const manifestInfo of integrationInfo.Manifests) {
				manifestInfo.StatusDescription = $scope.getSystemStatusDescription(manifestInfo.Status, true);
				manifestInfo.StatusDescriptionClass = isUpToDate(integrationInfo) ? "c-green-primary" : "c-orange";
				setManifestsErrorDescription(integrationInfo, manifestInfo);
			}
			fillStatusSummary(integrationInfo);
		}

		function setManifestsErrorDescription(integrationInfo, manifestInfo) {
			if (manifestInfo.Errors && manifestInfo.Errors.length > 0) {
				manifestInfo.ErrorDescription = disposalService.getIntegrationErrorMessageFromResponse(
					400,
					manifestInfo.Errors,
					integrationInfo.Description,
					true
				);
				if (
					manifestInfo.Errors[0].Code == Vgr.errorCodes.disposal.SystemUnavailable ||
					manifestInfo.Errors[0].Code == Vgr.errorCodes.disposal.SystemInstable
				) {
					manifestInfo.ActionInfo = $scope.labels.DISPOSAL_SYSTEM_INTEGRATION_SYSTEM_UNAVAILABLE;
					manifestInfo.ErrorDescription = null;
				} else {
					manifestInfo.ActionInfo = $scope.labels.DISPOSAL_SYSTEM_INTEGRATION_BAD_REQUEST_ERROR;
				}
			} else {
				manifestInfo.ErrorDescription = null;
			}
		}

		function setIntegrationFail(integrationInfo, response) {
			integrationInfo.ErrorDescription = disposalService.getIntegrationErrorMessageFromResponse(
				response.status,
				response.data,
				integrationInfo.Description,
				true
			);

			if (
				response &&
				response.data &&
				(response.data[0].Code == Vgr.errorCodes.disposal.SystemUnavailable ||
					response.data[0].Code == Vgr.errorCodes.disposal.SystemInstable)
			) {
				integrationInfo.ActionInfo = $scope.labels.DISPOSAL_SYSTEM_INTEGRATION_SYSTEM_UNAVAILABLE;
				integrationInfo.ErrorDescription = null;
			} else if (response.status == Vgr.enumerations.httpErrorCode.BadRequest) {
				integrationInfo.ActionInfo = $scope.labels.DISPOSAL_SYSTEM_INTEGRATION_BAD_REQUEST_ERROR;
			} else {
				integrationInfo.ActionInfo = $scope.labels.DISPOSAL_SYSTEM_INTEGRATION_INTERAL_ERROR;
			}
		}

		function setActionInfo() {
			for (const integrationInfo of $scope.integrationsInfo) {
				integrationInfo.ActionInfo = getActionInfo(integrationInfo);
			}
		}

		function getActionInfo(integrationInfo) {
			if (isUpToDate(integrationInfo)) {
				if (isReceived(integrationInfo)) {
					return "";
				}
			} else if (isTemporaryStorageReceived(integrationInfo)) {
				return $scope.labels.DISPOSAL_SYSTEM_INTEGRATION_CONTINUE_OR_WAIT_DESTINATOR;
			} else if (isComplementaryMtrEmitted(integrationInfo)) {
				return $scope.labels.DISPOSAL_SYSTEM_INTEGRATION_CONTINUE_OR_WAIT_DESTINATOR_RECEIVAL;
			} else if (isReceived(integrationInfo)) {
				return $scope.labels.DISPOSAL_SYSTEM_INTEGRATION_CONTINUE_OR_WAIT_CONFIRMATION;
			} else if (isCreated(integrationInfo)) {
				if (canUpdateStatus(integrationInfo)) {
					return $scope.labels.DISPOSAL_SYSTEM_INTEGRATION_CONTINUE;
				} else {
					return $scope.labels.IT_IS_NOT_POSSIBLE_TO_UPDATE_STATUS_FOR_THE_SYSTEM_X.replace(
						"[SYSTEM]",
						integrationInfo.Description
					);
				}
			} else if (isCanceledByAnyReason(integrationInfo)) {
				return $scope.labels.DISPOSAL_SYSTEM_INTEGRATION_NO_ACTION;
			}

			return "";
		}

		function setLoading(integrationInfo, loading) {
			if (loading) {
				integrationInfo.Loading = true;
				integrationInfo.LoadingDescription = $scope.labels.DISPOSAL_SYSTEM_INTEGRATION_LOADING;
			} else {
				integrationInfo.Loading = false;
			}
		}

		function getUpdateIntegrationDto(integrationInfo) {
			return {
				disposalId: $scope.destinationModel.Id,
				sourceId: integrationInfo.SourceId
			};
		}

		function getIntegrationInfoDateString(dateConverted, manifestToUpdate) {
			if (dateConverted) {
				return $scope.labels.RECEIVING_DATE_DETAIL.replace("[MTR_CODE]", manifestToUpdate.MtrCode).replace(
					"[DATE_STRING]",
					Vgr.util.dateToString(dateConverted)
				);
			} else if (sourceReturnsReceivedDateOnUpdate(manifestToUpdate.SourceId)) {
				return $scope.labels.NOT_RECEIVED_DETAIL.replace("[MTR_CODE]", manifestToUpdate.MtrCode);
			}
		}

		function sourceReturnsReceivedDateOnUpdate(sourceId) {
			return (
				sourceId != Vgr.enumerations.destination.systemSource.FEAM_MG &&
				sourceId != Vgr.enumerations.destination.systemSource.SINIR_BR &&
				sourceId != Vgr.enumerations.destination.systemSource.SIGOR_SP
			);
		}

		function loadIntegrationDetails(integrationInfo, updatedInfos, updateManifestsModel) {
			for (const updatedInfo of updatedInfos) {
				const updatedInfoV2 = Vgr.util.capitalizeObjectKeys(updatedInfo);
				const manifestToUpdate = integrationInfo.Manifests.find((m) => m.ManifestId == updatedInfoV2.Id);
				manifestToUpdate.ReceivedDate = Vgr.util.getDateFromString(updatedInfoV2.ReceivedDate);
				manifestToUpdate.ReceivedDateString = getIntegrationInfoDateString(
					manifestToUpdate.ReceivedDate,
					manifestToUpdate
				);
				manifestToUpdate.NumberOfChangedResidues = updatedInfoV2.NumberOfChangedResidues;
				manifestToUpdate.Status = ConvertCalculatedStatusToV2(updatedInfoV2.Status);

				if (updatedInfoV2.Residues) {
					for (const residue of updatedInfoV2.Residues) {
						manifestToUpdate.Residues.push(residue);
					}
				}

				if (updateManifestsModel) {
					updateDisposalManifestsModel(updatedInfoV2);
				}
			}
		}

		function updateDisposalManifestsModel(manifestInfo) {
			const dm = $scope.destinationModel.Manifests.find((m) => m.Source.Id == manifestInfo.SourceId);
			const oldStatus = dm.Status;
			const newStatus = ConvertCalculatedStatusToV2(manifestInfo.Status);
			if (dm) {
				dm.UpdateDate = manifestInfo.UpdateDate;
				dm.Errors = manifestInfo.Errors;
				dm.Status = newStatus;
			}

			$scope.onUpdateDisposalManifest(manifestInfo.SourceId, oldStatus, newStatus);
		}

		function ConvertCalculatedStatusToV2(status) {
			switch (status) {
				case Vgr.enumerations.destination.calculatedSystemStatus.Created:
					return Vgr.enumerations.destination.systemStatus.Created;
				case Vgr.enumerations.destination.calculatedSystemStatus.Canceled:
					return Vgr.enumerations.destination.systemStatus.Canceled;
				case Vgr.enumerations.destination.calculatedSystemStatus.Pending:
					return Vgr.enumerations.destination.systemStatus.Pending;
				case Vgr.enumerations.destination.calculatedSystemStatus.ComplementaryMTREmitted:
					return Vgr.enumerations.destination.systemStatus.ComplementaryMTREmitted;
				case Vgr.enumerations.destination.calculatedSystemStatus.ReceivedTemporaryStorage:
					return Vgr.enumerations.destination.systemStatus.ReceivedTemporaryStorage;
				case Vgr.enumerations.destination.calculatedSystemStatus.Provisional:
					return Vgr.enumerations.destination.systemStatus.Provisional;
				case Vgr.enumerations.destination.calculatedSystemStatus.Received:
					return Vgr.enumerations.destination.systemStatus.Received;
				case Vgr.enumerations.destination.calculatedSystemStatus.Disposed:
					return Vgr.enumerations.destination.systemStatus.Disposed;
				case Vgr.enumerations.destination.calculatedSystemStatus.Refused:
					return Vgr.enumerations.destination.systemStatus.Refused;
				case Vgr.enumerations.destination.calculatedSystemStatus.Processing:
					return Vgr.enumerations.destination.systemStatus.Pending;
				case Vgr.enumerations.destination.calculatedSystemStatus.PendingConfirmReceival:
					return Vgr.enumerations.destination.systemStatus.Received;
				case Vgr.enumerations.destination.calculatedSystemStatus.CanceledByDeadline:
					return Vgr.enumerations.destination.systemStatus.CanceledByDeadline;
				default:
					break;
			}
		}

		function onlyUnique(value, index, self) {
			return self.indexOf(value) === index;
		}
		function fillStatusSummary(integrationInfo) {
			const manifestsStatusDescription = integrationInfo.Manifests.map((m) => m.StatusDescription);
			if (integrationInfo.Manifests.length == 1 || manifestsStatusDescription.filter(onlyUnique).length == 1) {
				integrationInfo.StatusDescriptionSummary = integrationInfo.Manifests[0].StatusDescription;
				integrationInfo.StatusDescriptionClassSummary = integrationInfo.Manifests[0].StatusDescriptionClass;
			} else {
				let status;
				if (integrationInfo.Manifests.some((m) => m.Status == statusEnum.Canceled)) {
					status = statusEnum.Canceled;
				} else if (integrationInfo.Manifests.some((m) => m.Status == statusEnum.CanceledByDeadline)) {
					status = statusEnum.CanceledByDeadline;
				} else if (integrationInfo.Manifests.some((m) => m.Status == statusEnum.Pending)) {
					status = statusEnum.Pending;
				} else if (integrationInfo.Manifests.some((m) => m.Status == statusEnum.Provisional)) {
					status = statusEnum.Provisional;
				} else if (integrationInfo.Manifests.some((m) => m.Status == statusEnum.Refused)) {
					status = statusEnum.Refused;
				} else if (integrationInfo.Manifests.some((m) => m.Status == statusEnum.Created)) {
					status = statusEnum.Created;
				} else if (integrationInfo.Manifests.some((m) => m.Status == statusEnum.ReceivedTemporaryStorage)) {
					status = statusEnum.ReceivedTemporaryStorage;
				} else if (integrationInfo.Manifests.some((m) => m.Status == statusEnum.Received)) {
					status = statusEnum.Received;
				}
				integrationInfo.StatusDescriptionSummary = $scope.getSystemStatusDescription(status, true);
				integrationInfo.StatusDescriptionClassSummary = isUpToDate(integrationInfo) ? "c-green-primary" : "c-orange";
			}
		}
		function loadIntegrationSummary(integrationInfo) {
			fillDateSummary(integrationInfo);
			fillResiduesSummary(integrationInfo);
			setDisposalStatusInfo(integrationInfo);
		}

		function fillResiduesSummary(integrationInfo) {
			if (canShowResidues(integrationInfo)) {
				fillResiduesSummaryAllManifests(integrationInfo);
				fillResiduesSummaryPerManifest(integrationInfo);
			} else {
				integrationInfo.ResiduesDescriptionSummary = $scope.labels.RESIDUES_CHANGED_DESCRIPTION;
			}
		}

		function fillResiduesSummaryPerManifest(integrationInfo) {
			if (integrationInfo.Manifests.length > 1) {
				integrationInfo.Manifests.forEach((manifest) => {
					manifest.Residues.forEach((residue) => {
						manifest.ResiduesSummary.push(getResidueSummaryFromResidue(residue));
					});
				});
			} else {
				integrationInfo.Manifests[0].ResiduesSummary = integrationInfo.ResiduesSummary;
			}
		}

		function fillResiduesSummaryAllManifests(integrationInfo) {
			const groupResidues = integrationInfo.Manifests.reduce((grouped, manifests) => {
				for (const mtrResidue of manifests.Residues) {
					if (mtrResidue.Residue) {
						const residue = mtrResidue.Residue;
						grouped[residue.Id] = grouped[residue.Id] || [];
						grouped[residue.Id].push(mtrResidue);
					} else {
						grouped[mtrResidue.IbamaResidueId] = grouped[mtrResidue.IbamaResidueId] || [];
						grouped[mtrResidue.IbamaResidueId].push(mtrResidue);
					}
				}
				return grouped;
			}, []);
			for (const key in groupResidues) {
				const group = groupResidues[key];
				integrationInfo.ResiduesSummary.push(getResidueSummaryFromGroup(group));
			}
		}

		function getResidueSummaryFromGroup(mtrResidue) {
			return {
				DisposalType: mtrResidue[0].ReceivedAmount
					? mtrResidue[0].ReceivedDisposalTechnology
					: mtrResidue[0].IndicatedDisposalTechnology,
				HasChangedIbamaResidue: mtrResidue[0].HasChangedIbamaResidue,
				IndicatedIbamaResidue: mtrResidue[0].IndicatedIbamaResidue,
				ReceivedIbamaResidue: mtrResidue[0].ReceivedIbamaResidue,
				IndicatedMeasureUnit: mtrResidue[0].IndicatedMeasureUnit,
				IndicatedQuantity: Vgr.util.sumPropArray(mtrResidue, "IndicatedAmount"),
				ReceivedMeasureUnit: mtrResidue[0].ReceivedMeasureUnit,
				ReceivedQuantity: Vgr.util.sumPropArray(mtrResidue, "ReceivedAmount"),
				ResidueDescription: getResidueDescription(mtrResidue[0]),
				ResidueId: mtrResidue[0].Residue ? mtrResidue[0].Residue.DeprecatedResidueId : null
			};
		}

		function getResidueSummaryFromResidue(mtrResidue) {
			return {
				DisposalType: mtrResidue.ReceivedAmount
					? mtrResidue.ReceivedDisposalTechnology
					: mtrResidue.IndicatedDisposalTechnology,
				HasChangedIbamaResidue: mtrResidue.HasChangedIbamaResidue,
				IndicatedIbamaResidue: mtrResidue.IndicatedIbamaResidue,
				ReceivedIbamaResidue: mtrResidue.ReceivedIbamaResidue,
				IndicatedMeasureUnit: mtrResidue.IndicatedMeasureUnit,
				IndicatedQuantity: mtrResidue.IndicatedAmount,
				ReceivedMeasureUnit: mtrResidue.ReceivedMeasureUnit,
				ReceivedQuantity: mtrResidue.ReceivedAmount,
				ResidueDescription: getResidueDescription(mtrResidue),
				ResidueId: mtrResidue.Residue ? mtrResidue.Residue.DeprecatedResidueId : null
			};
		}

		function canShowResidues(integrationInfo) {
			return (
				integrationInfo.Manifests.length == 1 ||
				integrationInfo.Manifests.every(
					(m) =>
						m.Residues &&
						m.Residues.every(
							(res) =>
								!res.ReceivedMeasureUnit ||
								(res.ReceivedMeasureUnit && res.IndicatedMeasureUnit.Id == res.ReceivedMeasureUnit.Id)
						)
				)
			);
		}

		function getResidueDescription(item) {
			if (item.Residue) {
				return item.Residue.Name;
			}
			return item.ReceivedAmount ? item.ReceivedIbamaResidue : item.IndicatedIbamaResidue;
		}

		function fillDateSummary(integrationInfo) {
			const manifestReceivedDates = integrationInfo.Manifests.map((m) =>
				m.ReceivedDate ? Vgr.util.dateToString(m.ReceivedDate) : null
			);
			if (integrationInfo.Manifests.length == 1 || manifestReceivedDates.filter(onlyUnique).length == 1) {
				if (integrationInfo.Manifests[0].ReceivedDate) {
					integrationInfo.ReceiveDateSummary = $scope.labels.RECEIVING_DATE_DETAIL_SUMMARY.replace(
						"[DATE_STRING]",
						Vgr.util.dateToString(integrationInfo.Manifests[0].ReceivedDate)
					);
				}
			} else {
				const manifestReceivedDateString = integrationInfo.Manifests.map((m) => m.ReceivedDateString);
				integrationInfo.ReceiveDateSummary = manifestReceivedDateString.join("<br/>");
			}
		}

		function updateIntegrationFromGet(integrationInfo, updateManifestsModel) {
			setLoading(integrationInfo, true);

			const dto = {
				disposalId: $scope.destinationModel.Id,
				sourceIds: [integrationInfo.SourceId]
			};

			httpService.postDTOToServiceV2(residuesManagementService.listDisposalManifests, dto, true).then(
				function (updatedManifests) {
					loadIntegrationDetails(integrationInfo, updatedManifests, updateManifestsModel);
					loadIntegrationSummary(integrationInfo);
					setActionInfo();
					setLoading(integrationInfo, false);
				},
				function (error) {
					setIntegrationFail(integrationInfo, error);
					setLoading(integrationInfo, false);
				}
			);
		}

		function updateIntegration(integrationInfo) {
			if (!$scope.editPermission) {
				return;
			}
			setLoading(integrationInfo, true);

			listenForEvents(integrationInfo);
			httpService
				.postDTOToServiceV2(
					residuesManagementService.updateDisposalManifestsBySource,
					getUpdateIntegrationDto(integrationInfo),
					true
				)
				.then(
					function () {
						//async response, handled by socket
					},
					function (response) {
						asyncTimeoutService.cancelAsyncTimer(integrationInfo);
						unsubscribeTopic(integrationInfo);
						setIntegrationFail(integrationInfo, response);
						setLoading(integrationInfo, false);
					}
				);
		}

		$scope.isToShowReceivalData = function (integrationInfo) {
			return canGetDetailsMtr(integrationInfo) && (isReceived(integrationInfo) || isDisposed(integrationInfo));
		};

		function isUpToDate(integrationInfo) {
			return integrationInfo.Manifests.every((dm) => dm.IsUpToDateFunc(dm));
		}

		function isReceived(integrationInfo) {
			return integrationInfo.Manifests.every((dm) => dm.Status == statusEnum.Received);
		}

		function isDisposed(integrationInfo) {
			return integrationInfo.Manifests.every((dm) => dm.Status == statusEnum.Disposed);
		}

		function isTemporaryStorageReceived(integrationInfo) {
			return integrationInfo.Manifests.every((dm) => dm.Status == statusEnum.ReceivedTemporaryStorage);
		}

		function isComplementaryMtrEmitted(integrationInfo) {
			return integrationInfo.Manifests.every((dm) => dm.Status == statusEnum.ComplementaryMTREmitted);
		}

		function isCreated(integrationInfo) {
			return integrationInfo.Manifests.every((dm) => dm.Status == statusEnum.Created);
		}

		function isCanceledByAnyReason(integrationInfo) {
			return integrationInfo.Manifests.every(
				(dm) => dm.Status == statusEnum.Canceled || dm.Status == statusEnum.CanceledByDeadline
			);
		}

		function isProvisional(integrationInfo) {
			return integrationInfo.Manifests.every((dm) => dm.Status == statusEnum.Provisional);
		}
		function canUpdateStatus(integrationInfo) {
			return integrationInfo.Manifests.every((manifest) => manifest.actions.canUpdateStatus);
		}
		$scope.close = function () {
			dialogService.hide($scope.dialog);
		};

		function initializePopup() {
			setIntegrationsInfo();
			for (const integrationInfo of $scope.integrationsInfo) {
				setDisposalStatusInfo(integrationInfo);

				if (canGetDetailsMtr(integrationInfo)) {
					updateIntegrationFromGet(integrationInfo);
				} else if (canUpdateStatusMtr(integrationInfo)) {
					updateIntegration(integrationInfo);
				} else {
					integrationInfo.ActionInfo = getActionInfo(integrationInfo);
					loadIntegrationSummary(integrationInfo);
				}
			}
		}

		function canGetDetailsMtr(integrationInfo) {
			return integrationInfo.Manifests.every(
				(manifestInfo) => manifestInfo.IsUpToDateFunc(manifestInfo) && manifestInfo.actions.canViewDetails
			);
		}

		function canUpdateStatusMtr(integrationInfo) {
			const isStatusValidForUpdate =
				isCreated(integrationInfo) ||
				isReceived(integrationInfo) ||
				isTemporaryStorageReceived(integrationInfo) ||
				isComplementaryMtrEmitted(integrationInfo) ||
				isProvisional(integrationInfo);
			return isStatusValidForUpdate && canUpdateStatus(integrationInfo);
		}

		initializePopup();

		//init socket methods ---------------------------------------------------------------------------------------
		function subscribeTopic(integrationInfo) {
			integrationInfo.observable$ = window.Amplify.API.graphql(
				window.Amplify.graphqlOperation(Vgr.constants.graphql.subscriptions, {
					name: getRoomName($scope.destinationModel.Id, integrationInfo.SourceId)
				})
			).subscribe({
				next: ({ value }) => callBackTopicSucces(JSON.parse(value.data.subscribe2channel.data)),
				error: (error) => {
					console.log(error);
				}
			});

			return integrationInfo;
		}

		function unsubscribeTopic(integrationInfo) {
			if (integrationInfo.observable$) {
				integrationInfo.observable$.unsubscribe();
			}
		}

		function callBackTopicSucces(data) {
			const integrationInfo = $scope.integrationsInfo.find((e) => e.SourceId == data.sourceId);
			asyncTimeoutService.cancelAsyncTimer(integrationInfo);
			if (data.success) {
				updateIntegrationFromGet(integrationInfo, true);
			} else {
				onErrorUpdateIntegrationAsync(integrationInfo, data.errors);
			}
			unsubscribeTopic(integrationInfo);
		}

		function onErrorUpdateIntegrationAsync(integrationInfo, errors) {
			const errorsCapitalize = errors.map((e) => Vgr.util.capitalizeObjectKeys(e));
			setIntegrationFail(integrationInfo, {
				status: getStatusFromErrors(errorsCapitalize),
				data: errorsCapitalize
			});
			setLoading(integrationInfo, false);
		}

		function getStatusFromErrors(errors) {
			if (errors && errors.length > 0) {
				const badRequestError = errors.find((e) => e.Code && e.Code != "000");
				if (badRequestError) {
					return 400;
				}
			}
			return 500;
		}

		function getRoomName(disposalId, sourceId) {
			return "disposal_" + disposalId + "_source_" + sourceId;
		}

		function listenForEvents(integrationInfo) {
			integrationInfo = subscribeTopic(integrationInfo);
			asyncTimeoutService.startAsyncCallTimer(
				integrationInfo,
				onTimeoutNoResponseFromServer,
				asyncTimeoutService.getTimeoutBySystem(integrationInfo.SourceId),
				1
			);
		}

		function cancelAllTimers() {
			$scope.systemsToPopup.forEach((system) => {
				const integrationInfo = $scope.integrationsInfo.find((e) => e.SourceId == system.SourceId);
				asyncTimeoutService.cancelAsyncTimer(integrationInfo);
				unsubscribeTopic(integrationInfo);
			});
		}

		function onTimeoutNoResponseFromServer(integrationInfo) {
			updateIntegrationFromGet(integrationInfo, true);
			unsubscribeTopic(integrationInfo);
		}

		//end socket methods ---------------------------------------------------------------------------------------

		$scope.$on("$destroy", function () {
			cancelAllTimers();

			// nullify the DOM-bound model
			$scope.domElement = null;
		});
	}
]);
