angular.module("vgresiduos").controller("MtrFollowUpCtrl", [
	"$rootScope",
	function ($rootScope) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, "acompanhamento-mtrs");
		};
	}
]);
