angular.module("vgresiduos").controller("DestinationFollowupCtrl", [
	"$rootScope",
	"$scope",
	"$state",
	"$q",
	"deviceDetector",
	"accountService",
	"permissionService",
	"disposalPreferenceService",
	"residuesManagementService",
	"httpService",
	"engagementScoreService",
	"userAccessService",
	function (
		$rootScope,
		$scope,
		$state,
		$q,
		deviceDetector,
		accountService,
		permissionService,
		disposalPreferenceService,
		residuesManagementService,
		httpService,
		engagementScoreService,
		userAccessService
	) {
		"use strict";

		const viewPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.ViewDisposal);
		const createPermission = permissionService.hasPermissionV2(
			Vgr.systemPermissions.CreateEditDeleteDisposalUnfinished
		);
		const isLowerGenerationPlan = userAccessService.hasAccess(
			null,
			Vgr.constants.featureToggles.permission.lowerGenerationPlan
		);

		const loggedClient = accountService.getCurrentClient();
		$scope.unlockDisposalGatheringPermission = permissionService.hasPermissionV2(
			Vgr.systemPermissions.UnlockDisposalGathering
		);

		$scope.newOptions = [];
		$scope.list = [];
		$scope.status = Vgr.enumerations.destination.status;
		$scope.labels = $rootScope.labels;

		$scope.isMobile = function () {
			return deviceDetector.isMobile();
		};

		this.$onInit = function () {
			if (!viewPermission) {
				accountService.goHome();
			}

			if (isLowerGenerationPlan) {
				$state.go("residuesManagement", { tab: 1 });
				return;
			}

			disposalPreferenceService
				.getDisposalPreferences()
				.then(function (disposalPreferences) {
					$scope.disposalPreferences = disposalPreferences;
				})
				.finally(function () {
					addNewOptions();
				});

			loadContentNavBar();
		};

		function loadContentNavBar() {
			$rootScope.contentNavBar = {
				breadcrumb: $scope.labels.DISPOSAL_FOLLOWUP,
				title: $scope.labels.DISPOSAL_FOLLOWUP,
				customButtons: [],
				validate: false,
				valid: true,
				buttons: []
			};

			listPendingComprovationDisposals().then(
				function (response) {
					if ($rootScope.contentNavBar.customButtons && $rootScope.contentNavBar.customButtons.length == 0) {
						setNavBarButtons(response.count);
					}
				},
				function () {
					if ($rootScope.contentNavBar.customButtons && $rootScope.contentNavBar.customButtons.length == 0) {
						setNavBarButtons();
					}
				}
			);
		}

		function listPendingComprovationDisposals() {
			const params = {
				skip: 0,
				take: 1,
				status: Vgr.enumerations.destination.status.Pending,
				organizationUnitCode: loggedClient.code
			};

			return httpService.getListFromServiceCore(residuesManagementService.listDisposalsForFollowup, params, true);
		}

		function setNavBarButtons(count) {
			$rootScope.contentNavBar.customButtons = [];
			$rootScope.contentNavBar.buttons = [];

			addRefreshButton();

			if (count && count > 0 && !$scope.isMobile()) {
				addPendingCompravationButton(count);
			}

			addHistoryButton();
			addFollowMtrButton();
		}

		function addRefreshButton() {
			const refreshLabel = "";

			$rootScope.contentNavBar.customButtons = [
				{
					label: refreshLabel,
					buttonClasses: "button-refresh",
					icon: "refresh",
					supressToast: true,
					tooltip: $scope.labels.UPDATE,
					onClick: function () {
						return new Promise((resolve) => {
							$rootScope.$broadcast(Vgr.constants.evtUpdateFollowUpList);
							resolve();
						});
					}
				}
			];

			$rootScope.contentNavBar.buttons = [refreshLabel];
		}

		function addPendingCompravationButton(count) {
			const pendingComprovationlabel = $scope.labels.PENDING_COMPROVATION + " (" + count + ")";

			$rootScope.contentNavBar.customButtons.push({
				label: pendingComprovationlabel,
				icon: "announcement",
				type: "pending-destination-popup-button",
				buttonClasses: "md-alert md-raised",
				position: "right",
				minWidth: 350
			});

			$rootScope.contentNavBar.buttons.push(pendingComprovationlabel);
		}

		function addFollowMtrButton() {
			$rootScope.contentNavBar.customButtons.push({
				label: $scope.labels.FOLLOW_MTR,
				buttonClasses: "md-raised custom-btn-secondary",
				icon: "fact_check",
				supressToast: true,
				onClick: function () {
					$state.go("mtrFollowUp");
					engagementScoreService.track(Vgr.trackings.disposal.manifest.followupAccessUsingDisposalFollowupScreen);
					return $q.resolve();
				}
			});
			$rootScope.contentNavBar.buttons.push($scope.labels.FOLLOW_MTR);
		}

		function addHistoryButton() {
			const disposalHistoryLabel = $scope.isMobile()
				? $scope.labels.CREATE_SIMPLIFIED_DISPOSAL_BUTTON
				: $scope.labels.EXTERNAL_GATHERING_HISTORY;
			$rootScope.contentNavBar.customButtons.push({
				label: disposalHistoryLabel,
				buttonClasses: "md-primary",
				icon: "history",
				supressToast: true,
				onClick: function () {
					const deferred = $q.defer();
					$state.go("disposalHistory");
					deferred.resolve();
					return deferred.promise;
				}
			});
			$rootScope.contentNavBar.buttons.push(disposalHistoryLabel);
		}

		function addNewOptions() {
			if (createPermission) {
				$scope.newOptions.push({
					label: $scope.labels.SEE_DISPOSAL_MODELS,
					iconSvg: "img/icons/upload-button.svg",
					showTooltip: true,
					click: function () {
						$state.go("disposalModels");
					}
				});
			}
		}
	}
]);
