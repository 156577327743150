angular.module("vgresiduos").controller("clientResidueCtrl", [
	"$rootScope",
	"$scope",
	"httpService",
	"residueService",
	function ($rootScope, $scope, httpService, residueService) {
		"use strict";

		const labels = $rootScope.labels;

		this.$onInit = function () {
			$scope.label = $scope.$ctrl.label ? $scope.$ctrl.label : labels.RESIDUE;

			if ($scope.$ctrl.required) {
				$scope.label += "*";
			}

			$scope.subfieldList = [
				{
					item: "Code",
					label: labels.ID
				},
				{
					item: "GeneralResidueName",
					label: labels.RESIDUE_UNIFIED_BASE
				}
			];

			$scope.paginationOptions = {
				pageSize: 40,
				pageNumber: 1,
				query: ""
			};

			if ($scope.$ctrl.setterCode) {
				setModel();
			}
		};

		function setModel() {
			$scope.paginationOptions.query = $scope.$ctrl.setterCode;

			$scope.listResidues().then(function (residues) {
				$scope.$ctrl.model = residues.find(function (residue) {
					return residue.Code == $scope.$ctrl.setterCode;
				});
			});
		}

		$scope.listResidues = function () {
			if (!$scope.paginationOptions.query) {
				$scope.paginationOptions.query = "";
			}

			const params = {
				active: true,
				skip: $scope.paginationOptions.pageNumber - 1,
				take: $scope.paginationOptions.pageSize,
				nameLike: $scope.paginationOptions.query
			};

			return httpService.getListFromServiceV2(residueService.listClientResidues, params, "Residues");
		};
	}
]);
