angular.module("vgresiduos").controller("AuditModelCreateEditCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			if ($stateParams.id) {
				Vgr.util.redirectSingleton($rootScope, `organizacao/fornecedores/modelo-auditoria`, [
					{ key: "id", value: $stateParams.id }
				]);
			} else {
				Vgr.util.redirectSingleton($rootScope, `organizacao/fornecedores/modelo-auditoria`);
			}
		};
	}
]);
