angular.module("vgresiduos").controller("SupplierListCtrl", [
	"$scope",
	"$rootScope",
	"$state",
	"$stateParams",
	"localStorageService",
	"dialogService",
	"engagementScoreService",
	function ($scope, $rootScope, $state, $stateParams, localStorageService, dialogService, engagementScoreService) {
		"use strict";

		this.$onInit = function () {
			buildNewFeaturesPopups();
			Vgr.util.redirectSingleton($rootScope, `organizacao/fornecedores`, getQueries());
		};

		function getQueries() {
			const queries = [];
			if ($stateParams.pendencia && $stateParams.unidade) {
				queries.push({ key: "filterPendencyId", value: $stateParams.pendencia });
				queries.push({ key: "filterOrganizationUnitId", value: $stateParams.unidade });
			}
			if ($stateParams.vincular) {
				queries.push({ key: "linkUnitsToSupplier", value: true });
			}
			return queries;
		}

		function buildNewFeaturesPopups() {
			const reportData = buildCustomReportNewFeaturesPopup();
			if (reportData) {
				openNewFeaturesPopups(reportData);
			}
		}

		function buildCustomReportNewFeaturesPopup() {
			if (!localStorageService.get(Vgr.enumerations.newFeaturesKey.supplierFilter)) {
				return buildSupplierFilterPopup();
			}
		}

		function buildSupplierFilterPopup() {
			return {
				key: Vgr.enumerations.newFeaturesKey.supplierFilter,
				imageLink: "/img/generalTopAlerts/supplierFilter.svg",
				isManualAccess: true,
				text: $scope.labels.CONTACT_OUR_SUPPORT,
				isManualAccessKnowMoreBelow: false,
				knowMore: function () {
					trackEventAction(Vgr.trackings.supplier.supplierFilter.accessPopupManual);
					const url = Vgr.constants.zendesk.baseUrl + Vgr.constants.zendesk.guides.supplierFilter;
					window.open(url, "_blank");
				},
				buttons: [
					{
						label: $scope.labels.CLOSE,
						class: "md-primary button-large",
						click: function () {
							trackEventAction(Vgr.trackings.supplier.supplierFilter.closePopupNewFeature);
							dialogService.confirm();
						}
					}
				]
			};
		}

		function openNewFeaturesPopups(reportData) {
			const disablePopup = localStorageService.get(reportData.key);
			if (disablePopup || reportData == null) {
				return;
			}
			dialogService
				.showDialogFromTemplateV2(
					"views/components/generalTopAlerts/newFeatures/newFeaturesPopup_template.html",
					"NewFeaturesPopupCtrl",
					".vgr-page",
					{ popupModel: reportData },
					true
				)
				.then(
					function () {
						localStorageService.set(reportData.key, true);
					},
					function () {
						localStorageService.set(reportData.key, true);
					}
				);
		}

		function trackEventAction(event) {
			engagementScoreService.track(event);
		}
	}
]);
