angular.module("componentes").controller("LegalDocumentsCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.termsOfServiceUrl = Vgr.util.getTermsAndConditionsUrl();
		$scope.privacyUrl = Vgr.util.getPrivacyPolicyUrl();
		$scope.cookiePolicyUrl = Vgr.util.getCookiePolicyUrl();
	}
]);
