angular.module("vgresiduos").controller("disposalModelsCtrl", [
	"$scope",
	"$rootScope",
	"localStorageService",
	"dialogService",
	function ($scope, $rootScope, localStorageService, dialogService) {
		"use strict";

		this.$onInit = function () {
			buildNewFeaturesPopups();
			Vgr.util.redirectSingleton($rootScope, `disposal-models`);
		};

		function buildNewFeaturesPopups() {
			const reportData = buildEnhancedDisposalModelListScreenPopup();
			if (reportData) {
				openNewFeaturesPopups(reportData);
			}
		}

		function buildEnhancedDisposalModelListScreenPopup() {
			if (!localStorageService.get(Vgr.enumerations.newFeaturesKey.enhancedDisposalModelListScreen)) {
				return buildPopup();
			}
		}

		function buildPopup() {
			const featurePopupModel = getEnhancedDisposalModelListScreen();
			return {
				key: featurePopupModel.key,
				imageLink: featurePopupModel.imageLink,
				knowMore: featurePopupModel.knowMore,
				buttons: featurePopupModel.buttons,
				isManualAccess: featurePopupModel.isManualAccess,
				text: featurePopupModel.text,
				isFepamAlert: featurePopupModel.isFepamAlert,
				isManualAccessKnowMoreBelow: featurePopupModel.isManualAccessKnowMoreBelow
			};
		}

		function getEnhancedDisposalModelListScreen() {
			return {
				key: Vgr.enumerations.newFeaturesKey.enhancedDisposalModelListScreen,
				imageLink: "/img/generalTopAlerts/enhancedDisposalModelListScreen.svg",
				isManualAccess: true,
				text: $scope.labels.GENERAL_TOP_ALERTS.CONTACT_OUR_SUPPORT,
				knowMore: function () {
					const url = Vgr.constants.zendesk.baseUrl + Vgr.constants.zendesk.guides.enhancedDisposalModelListScreen;
					window.open(url, "_blank");
				},
				buttons: [
					{
						label: $scope.labels.CLOSE,
						class: "md-primary",
						click: function () {
							dialogService.confirm();
						}
					}
				]
			};
		}

		function openNewFeaturesPopups(reportData) {
			const disablePopup = localStorageService.get(reportData.key);
			if (disablePopup || reportData == null) {
				return;
			}
			dialogService
				.showDialogFromTemplateV2(
					"views/components/generalTopAlerts/newFeatures/newFeaturesPopup_template.html",
					"NewFeaturesPopupCtrl",
					".vgr-page",
					{ popupModel: reportData },
					true
				)
				.then(
					function () {
						localStorageService.set(reportData.key, true);
					},
					function () {
						localStorageService.set(reportData.key, true);
					}
				);
		}
	}
]);
