angular.module("vgresiduos").factory("ibamaExternalClientService", [
	"$http",
	"accountService",
	function ($http, accountService) {
		"use strict";

		const _listIbamaExternalClient = function () {
			const organizationId = accountService.getCurrentClient().organization.id;

			const req = {
				method: "get",
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.ibamaSystemClient.replace("[ORGANIZATION]", organizationId)
			};

			return $http(req);
		};

		return {
			listIbamaExternalClient: _listIbamaExternalClient
		};
	}
]);
