angular.module("vgresiduos").controller("AuditModelCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			if ($stateParams.success) {
				Vgr.util.redirectSingleton($rootScope, `organizacao/fornecedores/modelos-auditoria`, [
					{ key: "success", value: $stateParams.success }
				]);
			} else {
				Vgr.util.redirectSingleton($rootScope, `organizacao/fornecedores/modelos-auditoria`);
			}
		};
	}
]);
