angular.module("componentes").directive("loginForm", [
	function () {
		"use strict";
		return {
			scope: {},
			controller: "LoginFormCtrl",
			controllerAs: "ctrl",
			templateUrl: "views/login/components/loginForm_template.html"
		};
	}
]);
