angular.module("vgresiduos").controller("disposalCreateModelsCtrl", [
	"$rootScope",
	"$stateParams",
	"$scope",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `disposal-models/novo`, getQueries());
		};

		function getQueries() {
			return [{ key: "disposal", value: $stateParams.disposal }];
		}
	}
]);
