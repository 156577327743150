angular.module("vgresiduos").component("clientResidue", {
	controller: "clientResidueCtrl",
	templateUrl: "views/components/fields/clientResidue/clientResidue_template.html",
	bindings: {
		label: "@?",
		required: "<?",
		isDisabled: "<?",
		onChange: "<?",
		model: "=?",
		setterCode: "=?"
	}
});
